<template>
  <v-col md="4" cols="12" class="report_item">
    <v-card class="d-flex justify-start align-center moholand-shadow">
      <v-icon :style="style" dark>{{ icon }}</v-icon>
      <p>
        <span>{{ count }}</span>
        {{ title }}
      </p>
    </v-card>
  </v-col>
</template>

<script>
export default {
  data: () => ({}),
  computed: {
    style() {
      return "background-color: " + this.color;
    },
  },
  methods: {},
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
    },
    color: {
      type: String,
      default: "#fff",
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.report_item .v-card {
  border-radius: 10px !important;
}
.report_item .v-card .v-icon {
  padding: 10px;
  border-radius: 10px !important;
}
.report_item .v-card p {
  flex: 1 1 100%;
  justify-content: start;
  align-self: center;
  padding: 0 9px;
  margin: 0px;
}
.report_item .v-card p span {
  font-size: 17px;
  font-weight: bold;
}
</style>
