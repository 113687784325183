<script>
import { Line, mixins } from "vue-chartjs";

export default {
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: {
    chartdata: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      default: {
        legend: {
          display: true,
        },
        tooltips: {
          enabled: true,
          mode: "single",
          callbacks: {
            label(tooltipItems, data) {
              const { datasetIndex, index } = tooltipItems;
              const value = data.datasets[datasetIndex].data[index];
              if (parseInt(value, 10) > 999) {
                return `\u20AC ${value
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
              }
              return `\u20AC ${value}`;
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        height: 200,
      },
    },
  },
  mounted() {
    this.renderChart(this.chartdata, this.options);
  },
};
</script>
