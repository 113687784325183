<template>
  <v-container class="d-flex justify-center px-0" fluid>
    <v-row class="mt-n13" no-gutters>
      <v-col cols="12">
        <v-card
          class="moholand-shadow rounded-lg"
          :loading="loading"
          :disabled="loading"
        >
          <v-card-title>
            داشبورد
            {{
              $store.state.userData.roleName == "instructor"
                ? "مدرس"
                : "موهولندی"
            }}
          </v-card-title>

          <v-row class="pa-4">
            <ReportBox
              title="دانلود موفق"
              :count="downloads_count"
              color="#f54337"
              icon="mdi-bell-ring"
            />
            <ReportBox
              title="تومان خرید"
              :count="Intl.NumberFormat().format(payment_amount)"
              color="#4f99fc"
              icon="mdi-cart-outline"
            />
            <ReportBox
              title="نظر"
              :count="reviews_count"
              color="#ff6d00"
              icon="mdi-file-multiple-outline"
            />
          </v-row>
          <v-row>
            <v-img
              :src="require('@/assets/img/404.png')"
              class="rounded-xl mx-auto _404-img"
              contain
            />
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";

import Axios from "axios";
import MyAxios from "@/constants/MyAxios";

import ReportBox from "@/components/ReportBox";
import MyChart from "@/components/Charts/MyChart";

export default {
  name: "Dashboard_Customer",
  data: () => ({
    loading: false,
    downloads_count: 0,
    reviews_count: 0,
    payment_amount: 0,
  }),
  components: {
    ReportBox,
    MyChart,
  },
  created() {
    this.GetData();
  },
  methods: {
    GetData: function() {
      this.loading = true;
      MyAxios.get("/pages/dashboard", {
        params: {},
      })
        .then((response) => {
          console.log(response.data);
          this.downloads_count = response.data?.downloads_count || 0;
          this.reviews_count = response.data?.reviews_count || 0;
          this.payment_amount = response.data?.payment_amount || 0;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
._404-img {
  width: 400px;
  height: 400px;
}
</style>
