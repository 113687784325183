<template>
  <v-container class="d-flex justify-center px-0" fluid>
    <v-row class="mt-n13" no-gutters>
      <v-col cols="12">
        <v-card
          class="moholand-shadow rounded-lg"
          :loading="loading"
          :disabled="loading"
        >
          <v-card-title>
            داشبورد مدیرکل
          </v-card-title>

          <v-row class="pa-4">
            <ReportBox
              title="فایل در حال بررسی"
              :count="pending_products_count"
              color="#f54337"
              icon="mdi-file"
            />
            <ReportBox
              title="فروش"
              :count="sell_count"
              color="#4f99fc"
              icon="mdi-cart-outline"
            />
            <ReportBox
              title="تیکت جدید"
              :count="unread_tickets_count"
              color="#ff6d00"
              icon="mdi-pocket"
            />
          </v-row>

          <v-card-title>
            فروش این هفته
          </v-card-title>
          <v-row class="pb-8">
            <v-col cols="12">
              <!-- <MyChart /> -->
              <v-card
                class="mx-auto text-center"
                color="green"
                dark
                max-width="600"
              >
                <v-card-text>
                  <v-sheet color="rgba(0, 0, 0, .12)">
                    <v-sparkline
                      :value="value"
                      :labels="labels"
                      color="rgba(255, 255, 255, .7)"
                      height="100"
                      stroke-linecap="round"
                      smooth
                      auto-draw
                      auto-draw-duration="2000"
                    >
                      <template v-slot:label="item">
                        {{ item.value }}
                      </template>
                    </v-sparkline>
                  </v-sheet>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";

import Axios from "axios";
import MyAxios from "@/constants/MyAxios";

import ReportBox from "@/components/ReportBox";
import MyChart from "@/components/Charts/MyChart";

export default {
  name: "Dashboard_Seller",
  data: () => ({
    loading: true,
    pending_products_count: 0,
    unread_tickets_count: 0,
    sell_count: 0,
    value: [0, 0, 0, 0, 0, 0, 0],
    labels: [
      "شنبه",
      "یکشنبه",
      "دوشنبه",
      "سه شنبه",
      "چهارشنبه",
      "پنجشنبه",
      "جمعه",
    ],
  }),
  components: {
    ReportBox,
    MyChart,
  },
  mounted() {
    this.GetData();
  },
  methods: {
    GetData: function() {
      this.loading = true;
      MyAxios.get("/pages/dashboard", {
        params: {},
      })
        .then((response) => {
          this.pending_products_count =
            response.data?.pending_products_count || 0;
          this.sell_count = response.data?.sell_count || 0;
          this.unread_tickets_count = response.data?.unread_tickets_count || 0;
          this.value = response.data?.sell_in_month || 0;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
