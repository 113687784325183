<template>
  <div>
    <dashboard-customer v-if="role == 'user' || role == 'instructor'" />
    <dashboard-seller v-else-if="role == 'vendor'" />
    <dashboard-admin v-else-if="role == 'admin'" />
  </div>
</template>

<script>
import DashboardAdmin from "./Dashboard_Admin.vue";
import DashboardSeller from "./Dashboard_Seller.vue";
import DashboardCustomer from "./Dashboard_Customer.vue";

export default {
  data() {
    return {
      role:
        this.$store.state.userData?.roles?.findIndex(
          (val) => val.name == "admin"
        ) >= 0
          ? "admin"
          : this.$store.state?.userData?.roleName,
    };
  },
  components: {
    DashboardSeller,
    DashboardCustomer,
    DashboardAdmin,
  },
};
</script>

<style scoped></style>
